

















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Breadcrumb extends Vue {
  @Prop() otherItems!: object[];

  get items(): any {
    const { breadcrumb = [] } = this.$route.meta;
    return [...breadcrumb, ...this.otherItems];
  }
}
