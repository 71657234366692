


































import { Component, Vue, Prop } from "vue-property-decorator";

import EventBus from "@/components/shared/EventBus.vue";

@Component
export default class LeftMenu extends Vue {
  isOpen: boolean = true;

  groups: any = [
    {
      label: "Dashboard",
      items: [
        {
          label: "Dashboard",
          icon: "mdi-view-dashboard",
          routeName: "dashboard",
        },
      ],
    },
    {
      label: "Estimate",
      items: [
        { label: "Estimation", icon: "mdi-hammer", routeName: "estimates" },
      ],
    },
  ];

  mounted() {
    EventBus.$on("handleToggleDrawer", this.handleToggleDrawer);
  }

  destroyed() {
    EventBus.$off("handleToggleDrawer", this.handleToggleDrawer);
  }

  handleOnClick({ routeName }: any) {
    this.$router.push({ name: routeName });
  }

  handleToggleDrawer() {
    this.isOpen = !this.isOpen;
  }
}
