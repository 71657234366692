import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../components/Auth/Login.vue";

import DefaultTemplate from "@/components/shared/DefaultTemplate.vue";

import Dashboard from "../components/Dashboard.vue";

import EstimateList from "../components/Estimate/EstimateList.vue";
import EstimateForm from "../components/Estimate/EstimateForm.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/auth", name: "auth", component: Login },
  {
    path: "/",
    name: "home",
    component: DefaultTemplate,
    redirect: "dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/estimates",
        name: "estimates",
        component: EstimateList,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Estimate list" },
          ],
        },
      },
      {
        path: "/edit-estimate/:estimateId",
        name: "edit-estimate",
        component: EstimateForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Estimate list", route: "estimates" },
            { label: "Edit estimate" },
          ],
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
